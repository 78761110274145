import { useState } from "react";
import styled from "styled-components";
import { SectionTitle } from "../components/SectionTitle";

import imageAss from "../assets/gallery/1.png";
import image2 from "../assets/gallery/2.jpeg";
import image3 from "../assets/gallery/3.png";
import image4 from "../assets/gallery/4.jpg";
import image5 from "../assets/gallery/5.jpg";
import image6 from "../assets/gallery/6.png";
import image7 from "../assets/gallery/7.jpeg";
import image8 from "../assets/gallery/8.jpg";
import image9 from "../assets/gallery/9.png";
import image10 from "../assets/gallery/10.jpg";
import about from "../assets/gallery/about.jpg";
import alluring from "../assets/gallery/Alluring.jpeg";
import choking from "../assets/gallery/chocking.jpeg";
import corsetBed from "../assets/gallery/corsetBed.jpeg";
import cropAndFur from "../assets/gallery/cropAndFur.jpeg";
import latexPerfection from "../assets/gallery/latexPerfection.jpeg";
import latexSunset from "../assets/gallery/latexSunset.jpeg";
import legsForDays from "../assets/gallery/LegsForDays.jpeg";
import middleF from "../assets/gallery/middleF.jpeg";
import LeatherBootsAndCrop from "../assets/gallery/leatherBootsAndCrop.jpeg";

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import { Fullscreen, Slideshow } from "yet-another-react-lightbox/plugins";
import { QuoteStyleText, QuoteStyleTextWrapper } from "./AboutSection";

const photos = [
  {
    id: "alluring",
    src: alluring,
    width: 1080,
    height: 1650,
  },
  {
    id: "corsetBed",
    src: corsetBed,
    width: 1950,
    height: 1080,
  },

  {
    id: LeatherBootsAndCrop,
    src: LeatherBootsAndCrop,
    width: 300,
    height: 300,
  },
  {
    id: "imageAss",
    src: imageAss,
    width: 350,
    height: 300,
  },
  {
    id: "latexPerfection",
    src: latexPerfection,
    width: 1080,
    height: 1650,
  },
  {
    id: "about",
    src: about,
    width: 300,
    height: 400,
  },
  {
    id: "cropAndFur",
    src: cropAndFur,
    width: 1080,
    height: 1650,
  },

  {
    id: "strapon",
    src: image3,
    width: 300,
    height: 200,
  },
  {
    id: "redboots",
    src: image4,
    width: 300,
    height: 370,
  },
  {
    id: "accessories",
    src: image5,
    width: 400,
    height: 300,
  },

  {
    id: "latexSunset",
    src: latexSunset,
    width: 1080,
    height: 1650,
  },
  {
    id: "you",
    src: image6,
    width: 400,
    height: 520,
  },
  {
    id: "blackcorset",
    src: image7,
    width: 300,
    height: 370,
  },
  {
    id: "legsForDays",
    src: legsForDays,
    width: 1080,
    height: 1650,
  },

  {
    id: "chairchampagne",
    src: image8,
    width: 400,
    height: 300,
  },
  {
    id: "cuffsWhip",
    src: image9,
    with: 300,
    height: 300,
  },
  {
    id: "chocking",
    src: choking,
    width: 300,
    height: 500,
  },
  {
    id: "middleF",
    src: middleF,
    width: 1080,
    height: 1650,
  },
  {
    id: "corsetseated",
    src: image10,
    width: 300,
    height: 360,
  },
  {
    id: "boots",
    src: image2,
    width: 300,
    height: 400,
  },
];

export const GallerySection = () => {
  const [index, setIndex] = useState(-1);

  return (
    <>
      <GalleryContainer>
        <SectionTitle title="Gallery" />

        <br />
        <QuoteStyleTextWrapper>
          <QuoteStyleText>
            {/* Feel the friction. Torturous delights. */}
          </QuoteStyleText>
        </QuoteStyleTextWrapper>
        <br />

        <PhotoAlbum
          photos={photos}
          layout="masonry"
          columns={(containerWidth) => {
            if (containerWidth < 500) return 2;
          }}
          onClick={({ index }) => setIndex(index)}
        />
        <Lightbox
          open={index >= 0}
          close={() => setIndex(-1)}
          slides={photos}
          index={index}
          plugins={[Fullscreen, Slideshow]}
        />
      </GalleryContainer>
    </>
  );
};

const GalleryContainer = styled.div`
  background-color: black;
  padding: 5rem 2rem;
  margin-top: -5rem;

  @media screen and (max-width: 500px) {
    padding: 1rem 0.5rem;
  }
`;
