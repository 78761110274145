import React from "react";
import styled from "styled-components";
import { SectionContainer } from "../components/SectionContainer";
import { SectionTitle } from "../components/SectionTitle";
import LondonTour from "../assets/gallery/Alluring.jpeg";
import { PrimaryButton } from "../components/Buttons";
import { Link } from "react-router-dom/cjs/react-router-dom";

export const PromoBannerSection = () => {
  return (
    <>
      <div id="promo" style={{ color: "transparent" }}>
        About
      </div>
      <SectionContainer background={"#200"} center>
        <AboutContent>
          <SectionTitle
            title="London Tour"
            mobileOnly
            backdropText={"London"}
          />
          <div style={{ display: "flex", flexWrap: "wrap-reverse" }}>
            <AboutText>
              <SectionTitle
                title="London Tour"
                backdropText={"London"}
                hideOnMobile
                style={{ marginTop: "-50px" }}
              />
              <br />
              <QuoteStyleTextWrapper>
                <QuoteStyleText>April, 2025</QuoteStyleText>
              </QuoteStyleTextWrapper>
              <br />
              For my very first trip to London I expect no less than minds
              blown, tears of joy and pleasure marks. I think these are fair
              expectations, don't you?
              <br />
              <br />
              I'll have the joy of hosting sesions this time, in a fine dungeon
              in central London. There we will experience wicked pleasures known
              only to ones with the most refined sexual appetites - Female
              Domination.
              <br />
              <br />
              Would you like to learn more?
              <br />
              <br />
              <br />
              <div
                style={{ width: "100%", margin: "0 auto", textAlign: "center" }}
              >
                <Link to="/london">
                  <PrimaryButton>Yes please, Ma'am!</PrimaryButton>
                </Link>
              </div>
            </AboutText>
            <ImageContainer />
          </div>
        </AboutContent>
      </SectionContainer>
    </>
  );
};

const AboutContent = styled.article`
  line-height: 2;
  margin-bottom: 4rem;
  display: flex;
  gap: 28px;
  max-width: 1200px;
  font-size: 18px;
  line-height: 2;
  width: 100%;

  @media screen and (max-width: 468px) {
    flex-direction: column;
  }

  @media screen and (max-height: 500px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  width: 40%;
  height: 80vh;
  background-image: url(${LondonTour});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 468px) {
    width: 100%;
  }

  @media screen and (max-height: 468px) {
    width: 60%;
    height: 150vh;
    margin: 0 auto;
  }
`;

const AboutText = styled.div`
  width: 60%;

  @media screen and (max-width: 468px) {
    width: 100%;
    font-size: 18px;
  }

  @media screen and (max-height: 468px) {
    width: 100%;
    font-size: 18px;
  }
`;

export const QuoteStyleTextWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  justify-content: center;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const QuoteStyleText = styled.span`
  font-style: italic;
  text-align: center;
  font-size: 24px;
  margin-right: 5px;
`;
