import React from "react";

import "./App.css";
import "./styles";
import "./menu.css";
import "yet-another-react-lightbox/styles.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import { InPersonSession } from "./pages/InPersonSession";
import { Navbar } from "./components/Navbar";
import { MentoringPage } from "./pages/Mentoring";
import { RemotePage } from "./pages/Remote";
import { Footer } from "./components/Footer";

import { FormPage } from "./pages/Form";
import { LondonPage } from "./pages/London";

export default function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/sessions">
            <InPersonSession />
          </Route>

          <Route path="/mentoring">
            <MentoringPage />
          </Route>

          <Route path="/remote">
            <RemotePage />
          </Route>

          <Route path="/form">
            <FormPage />
          </Route>

          <Route path="/london">
            <LondonPage />
          </Route>

          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
}
