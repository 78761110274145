import { useEffect, useRef } from "react";

const LoyalFansWidget = () => {
  const widgetRef = useRef(null);

  useEffect(() => {
    const scriptId = "lf-widget-v3-script";
    const scriptSrc = "https://www.loyalfans.com/widget/js/widget.v3.js";

    const container = widgetRef.current; // Store the ref value in a local variable

    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = scriptSrc;
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        if (container) {
          const widgetDiv = document.createElement("div");
          widgetDiv.className = "lf-widget-v3";
          widgetDiv.setAttribute("data-id", "mayamistress");
          widgetDiv.setAttribute("data-t", "dark");
          widgetDiv.setAttribute("data-width", "336");
          widgetDiv.setAttribute("data-height", "280");
          widgetDiv.setAttribute("data-s", "size1");
          container.appendChild(widgetDiv);
        }
      };
    }

    return () => {
      if (container) {
        container.innerHTML = ""; // Clear only this specific instance
      }
    };
  }, []);

  return <div ref={widgetRef} />;
};

export default LoyalFansWidget;
