import "../App.css";
import { HomeSection } from "../sections/HomeSection";
import { AboutSection } from "../sections/AboutSection";
import { ProjectsSection } from "../sections/ProjectsSection";
import { QuoteSection } from "../sections/QuoteSection";
import { ContactSection } from "../sections/ContactSection";
import { Helmet } from "react-helmet";
import "../styles";

import "../menu.css";
import { GallerySection } from "../sections/GallerySection";
import { PromoBannerSection } from "../sections/PromoBannerSections";

function Home() {
  return (
    <>
      <Helmet>
        <title>Maya Mistress | Independent Dominatrix in Amsterdam </title>
        <meta
          name="Maya Mistress | Independent Dominatrix in Amsterdam"
          content="Independent Pro Domme based in Amsterdam"
        />
        <link rel="canonical" href="https://mayamistress.nl" />
      </Helmet>
      <HomeSection />
      <AboutSection />
      <QuoteSection />
      <PromoBannerSection />
      <ProjectsSection />
      <GallerySection />

      <ContactSection />
    </>
  );
}

export default Home;
