import { SectionTitle } from "../components/SectionTitle";
import {
  ContentArea,
  InPersonSessionImage,
  IntroArea,
  IntroTextContainer,
  SessionImageContainer,
  SessionPageContainer,
  StepDescription,
  SubTitle,
  TableContainer,
  TributesWrapper,
} from "../components/SessionPageComponents";
import Alluring from "../assets/gallery/Alluring.jpeg";
import LegsForDays from "../assets/gallery/LegsForDays.jpeg";
import { TextContainer } from "../sections/ContactSection";
import LoyalFansWidget from "../components/LFWidget";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

export const LondonPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Maya Mistress | London Tour | Brazilian Dominatrix based in Amsterdam
        </title>
        <meta
          name="Maya Mistress | Brazilian Dominatrix | London Tour "
          content="Independent Pro Domme in London | Based in Amsterdam"
        />
        <link rel="canonical" href="https://mayamistress.nl" />
      </Helmet>

      <SessionPageContainer>
        <ContentArea>
          <SectionTitle title="London" />
          <SubTitle>April, 2025</SubTitle>
          <br />
          <IntroArea invertOrderOnMobile>
            <IntroTextContainer>
              Helllo London,
              <br />
              <br />
              For the very first time I'll be travelling to you, and I'm so
              excited to meet you. I'm a Brazilian Dominatrix with 7 years of
              experience, based in Amsterdam, where I've been for the last 5
              years. My journey began in the Academia, where I wrote my Thesis
              about Power Dynamics. Back then, it was completely - or almost
              completely - unrelated to sexuality. But not much long after my
              graduation, I had the break through to try out the power of FemDom
              - and I was transformed ever since.
              <br />
              <br />
              Today, I take pride and pleasure to offer such liberating
              experiences to others - and to myself - in a professional basis.
              Being a Dominatrix is in my true nature, and I embrace and
              celebrate this fact. And I have my best sessions with submissives
              who strive to have a healthy and proud relationship with their
              kinks and fetishes as well.
              <br />
              <br />I welcome consenting adults of all genders and backgrounds.
              Couples are also more than welcome, however please note that
              different Tributes apply according to what you look for.
            </IntroTextContainer>

            <SessionImageContainer>
              <InPersonSessionImage image={Alluring} />
            </SessionImageContainer>
          </IntroArea>
          <br />
          <br />
          <SubTitle>My Domination Style</SubTitle>
          <IntroArea>
            <SessionImageContainer>
              <InPersonSessionImage image={LegsForDays} />
            </SessionImageContainer>
            <IntroTextContainer>
              Power Dynamics is my specialty, and 'special spot' when it comes
              to FemDom. I love to create a slow-motion, hypnotic vibe through
              seduction and and sensuality and gradually amp up intensity. I'm a
              passionate about building the fricction between desire & denial,
              tension & laughter, agony & ecstasy... With precision, care, and a
              lot of kinky lust, I seduce my preys into submission. And once I
              win you over, I will use you like the little play tool I made you
              to be. Getting your total surrender is just the beginning for Me -
              and the end of you.
              <br />
              Here are some examples of techniques I use to achieve that:
              <ul>
                <li>Clear expectations and safewords</li>
                <li>Power Play and Total Power Exchange</li>
                <li>Gorgeous outfits in leather, latex and high heels</li>
                <li>Bondage, specially using rope</li>
                <li>A mix of soft touches with hard core spanking</li>
                <li>Dirty talk, humiliation and mindfucks</li>
                <li>Pure, primal, unfiltered Sadism</li>
                <li>A touch of Female Supremacy</li>
                <li>Worship, devotion and surrender to Me</li>
                <li>Play with My glorious, gorgeous, sensitive Feet</li>
              </ul>
              <br />
              <br />
            </IntroTextContainer>
          </IntroArea>
          <SubTitle>How to Apply</SubTitle>
          <TextContainer>
            <StepDescription>
              1 - Introduce yourself in form below.
            </StepDescription>
            <StepDescription>
              2 - Receive my exact dates and session locations, and my unique
              Form to Apply to Serve.
            </StepDescription>
            <StepDescription>
              3 - If approved, you'll be requested a deposit to lock in your
              date and time
            </StepDescription>
            <StepDescription>
              4 - Introductory audio call (pre-scheduled): break the ice, get
              acquainted, answer any pending questions - no extra charge.
            </StepDescription>
            <StepDescription>5 - Play time!</StepDescription>
          </TextContainer>
          <br />
          <br />
          <TributesWrapper>
            <SubTitle>Tributes and Conditions</SubTitle>
            <ul>
              <li>
                Deposits: A deposit will be required to lock in your date and
                time.
              </li>
              <br />
              <li>
                Outcalls: I accept outcalls to fine hotels and other dungeons.
                Depending on the distance, a taxi fee might be requested.
              </li>
              <br />
              <li>
                Rounds: I prefer to not be checking the time constantly during
                our session, that's why I developed this system of "rounds" with
                expected lengths. A "round" is a play time with a natural
                beginning, middle and end - followed by some talk, unwinding and
                after care. This way I can follow the natural rythm of the
                session and keep time expectations in check.
              </li>
              <br />
              <li>
                Aftercare: Some intro talk and after care time is also included
                within the agreed time.
              </li>
              <br />
              <li>
                Longer sessions: Sessions longer than 3h require some social /
                outing time. If that's not possible for you for any reason, let
                me know in advance and we can discuss alternatives.
              </li>
            </ul>
            <br />
            <TableContainer>
              <table>
                <thead>
                  <td>Session Type</td>
                  <td>Estimated length*</td>
                  <td>Tribute</td>
                </thead>
                <tbody>
                  <tr>
                    <td>Incall - One Round</td>
                    <td>2h</td>
                    <td>700 £</td>
                  </tr>
                  <tr>
                    <td>Incall - Two Rounds</td>
                    <td> 3h </td>
                    <td>1000 £</td>
                  </tr>
                  <tr>
                    <td>Dinner + be My dessert</td>
                    <td>4h - 5h</td>
                    <td>1500 £</td>
                  </tr>

                  <tr>
                    <td>Evening + Overnight</td>
                    <td>12h - 14h</td>
                    <td>2800 £</td>
                  </tr>
                  <tr>
                    <td>My Slave for a day</td>
                    <td>24h</td>
                    <td>3500 £</td>
                  </tr>
                  <tr>
                    <td>Serve Me for a Weekend</td>
                    <td>48h</td>
                    <td>5000 £</td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
            <legend style={{ textAlign: "center", fontSize: "12px" }}>
              *Please note that lengths can varry slightly, as I prioritise a
              natural flow over keeping an eye at a watch the whole session.
            </legend>
            <br />
          </TributesWrapper>
          <SubTitle>Are you game?</SubTitle>

          <iframe
            title="tour form"
            src="https://blocksurvey.io/tour-form-i8feQ2BxT2.4LZ4WCHvnEQ?v=o"
            style={{
              width: "100%",
              height: "70vh",
              border: "none",
            }}
          >
            Loading…
          </iframe>

          <br />
          <br />
          <SubTitle>Don't want to wait?</SubTitle>
          <TextContainer>
            <div style={{ margin: "0 auto", textAlign: "center" }}>
              We can connect right now via Loyal Fans
              <br />
              <br />
              <LoyalFansWidget />
            </div>
          </TextContainer>
        </ContentArea>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </SessionPageContainer>
    </>
  );
};
